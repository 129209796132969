



























































import { Component, Prop, Vue } from 'vue-property-decorator'
import axios from 'axios'
import Option from '@/types/option'
import { notification } from 'ant-design-vue'
import User from '@/types/user'

class MemberSelectionModal {
  visible = false
  loading = false
  selectedIds = []
  groupId = 0
  multiple = false
}

interface Member {
  id: number
  name: string
  active: boolean
  group_id?: number
  group?: string
}

@Component

export default class ProjectMemberGroup extends Vue {
  @Prop({ required: true }) groupLabel!: string
  @Prop({ required: true }) role!: string
  @Prop({ required: true }) groupId!: number
  @Prop({ required: false, default: () => [] }) members!: Member[]
  @Prop({ required: false, type: Boolean, default: false }) multiple!: boolean
  @Prop({ required: false, type: Boolean, default: false }) canEdit!: boolean
  @Prop({ required: false, type: Boolean, default: false }) loading!: boolean

  modal = new MemberSelectionModal()
  fetchedMembers: Member[] = []
  fetching = false
  storing = false
  hasMembers = true

  get isBusy (): boolean {
    return this.fetching || this.storing || this.loading || this.modal.visible
  }

  get projectId (): number {
    return this.$store.getters.currentProject.id
  }

  get filteredMembers (): Member[] {
    return this.members.filter((member:Member) => member.group_id === this.groupId).sort((a, b) => a.name.localeCompare(b.name))
  }

  get buttonVisible (): boolean {
    return this.canEdit && !this.isBusy && (this.multiple || this.filteredMembers.length === 0)
  }

  inactiveTooltip (active: boolean): string {
    return !active ? 'Gebruiker is niet meer actief' : ''
  }

  onRemove (e:Event, memberId:number): void {
    e.preventDefault()
    this.removeMember(memberId)
  }

  showModal (): void {
    this.modal.visible = true
    this.modal.multiple = this.multiple
    this.modal.selectedIds = []
    this.fetchedMembers = []
    this.fetchMembers()
  }

  fetchMembers (): void {
    this.fetching = true
    axios.get(`${process.env.VUE_APP_API_URL}/users?filter[roles.name]=${this.role}&limit=2500`)
      .then((response) => {
        const users = response.data.data as User
        this.fetchedMembers = users.map((user:User) => {
          return {
            id: user.id,
            name: user.full_name,
            active: user.active
          }
        })
      })
      .catch(() => {
        this.$notification.error({
          message: 'Fout bij ophalen van gebruikers',
          description: 'Er is een fout opgetreden bij het ophalen van de gebruikers.'
        })
      })
      .finally(() => {
        this.fetching = false
        this.hasMembers = this.fetchedMembers.length > 0
      })
  }

  filterMembers (input:string, option: Option): boolean {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  onSelected (id: number): void {
    this.addMember(id)

    if (!this.multiple) {
      this.closeModal()
    }
  }

  removeMember (userId:number): void {
    this.storing = true

    const payload = {
      project_id: this.projectId,
      user_id: userId,
      group_id: this.groupId
    }

    axios.patch(`${process.env.VUE_APP_API_URL}/members/${this.projectId}`, payload)
      .then(() => {
        notification.success({ message: 'Succesvol ontkoppeld.', description: '', duration: 3 })
        const member = this.members.find((member:Member) => member.id === userId && member.group_id === this.groupId)
        if (!member) return

        this.$emit('removed', member)
      })
      .catch((err: Error) => {
        notification.error({ message: 'Fout bij het ontkoppelen!', description: 'Ontkoppelen is mislukt. Als dit blijft gebeuren, meld dit dan als feedback.', duration: 3 })
        console.log(err)
      })
      .finally(() => {
        this.storing = false
      })
  }

  addMember (userId:number): void {
    this.storing = true

    const payload = {
      project_id: this.projectId,
      user_id: userId,
      group_id: this.groupId
    }

    axios.post(`${process.env.VUE_APP_API_URL}/members/${this.projectId}`, payload)
      .then(() => {
        notification.success({ message: 'Succesvol gekoppeld.', description: '', duration: 3 })
        const member = this.fetchedMembers.find((member:Member) => member.id === userId)
        if (!member) return

        this.$emit('added', {
          id: member.id,
          name: member.name,
          active: member.active,
          group_id: this.groupId
        })
      })
      .catch((err:Error) => {
        notification.error({ message: 'Fout bij het koppelen!', description: 'Het koppelen is niet gelukt. Als dit blijft gebeuren, meld dit dan als feedback.', duration: 3 })
        console.log(err)
      }).finally(() => {
        this.storing = false
      })
  }

  closeModal (): void {
    this.modal.visible = false
    this.modal.selectedIds = []
  }
}
